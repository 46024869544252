/**
 * @file app.constants.ts
 * @description Constants for app module
 */
export class AppConstants {
  public static readonly ERROR = {
    MESSAGES: {
      GENERIC: 'We ran into an unexpected error. Please try again.',
      NO_REPORTS_DATA: 'Sorry, missing report data, please contact technical support for questions if any.',
      FATAL: 'We ran into an unexpected error. Please refresh the application.',
      DESCRIPTION: `This is your one-stop-shop for reporting and analytics.  Through this easy-to-use portal, 
        you have access to a wealth of data and real-time metrics that show you all the benefits of your partnership with EmpiRx Health.`,
      DESCRIPTION_NEW: `This is your one-stop-shop for reporting and analytics. Through this easy-to-use portal, you have access to a wealth of data and realtime metrics that show you all the benefits of your partnership with EmpiRx Health.`,
      DESCRIPTION_NEW_2: `This site gives you tools to configure eligibility, view plan designs, and manage patient profiles.`,
      NOCLIENTS: `Sorry but there is no client id associated with this user. Please contact administrator.`,
      NODOCUMENTS: `Documents are not present for this Client.`,
      NOINVOICES: `No Documents Found`,
      NO_DATA_MATCH: 'No data matching with',
      NO_ACCESS:
        "User doesn't have access to 'Reports' or 'Benefits and Eligibility'",
      NO_ACCESS_TO_MODULE: "User don't have access to this page"
    },
    GENERIC_ERROR_MESSAGE: "Sorry, something went wrong. Please contact technical support.",
    WAIT_MESSAGE_FOR_UPDATE_MEMBER: "This process will take few minutes.",
  };

  public static readonly LANGUAGE = {
    DEFAULT: 'en',
    ENGLISH: 'en',
    SPANISH: 'es',
  };

  public static readonly HISTORICAL_DATA_REQUEST = {
    CLAIMS: 'CLAIMS',
    CREATED_BY: 'BAP'
  }

  public static readonly LOADER = {
    DEFAULT_MESSAGE: 'Loading',
  };

  public static UTILS = {
    N: 'N',
    Y: 'Y',
    VIEW: 'view',
    DOWNLOAD: 'download'
  }

  public static readonly URLS = {
    CLIENTS: `/User/GetUserIdentity?emailId=`,
    MENUS: `/User/GetReports?clientId=`,
    DASHBOARD: `/Domo/GetDomoData`,
    DOCUMENTS: `/Domo/GetDomoDocument?clientId=`,
    DOCUMENTSBYID: `/Domo/GetDocById`,
    INVOICES: `/Domo/GetFinancialInvoices?clientId=`,
    INVOICESBYID: `/Domo/GetInvoiceDocById`,
    USER_ACCESS: '/User/GetUserType',
    BAP_CLIENTS: '/BAPUser/GetBAPUserClients?emailId=',
    BAP_GROUP_ACCESS: '/BAPUser/GetBAPGroupUserAccess'
  };

  public static readonly LABELS = {
    email: 'email',
    selectedId: 'selectedClientId',
    yes: 'Y',
    embedId: 'embedId',
    welcome: 'Welcome!',
    portal: `to your report portal`,
    combinedReport: `Report by Combined Client`,
    or: 'OR',
    individualClient: 'Report by Client',
    continueReport: 'Continue to Your Report',
    userName: 'userName',
    clientName: 'clientName',
    isParentId: 'isParentId',
    clientLevel: 'clientLevel',
    reportType: 'reportType',
    isSingleClient: 'isSingleClient',
    id_token: 'id_token',
    login_date_time: 'login_date_time',
    backToClientSelection: 'Back to Client Selection',
    documents: 'My Documents',
    whoWeAre: 'Who we are',
    fileName: 'File Name',
    dateModified: 'Date Modified',
    clientTableName: 'Client Name',
    fileType: 'File Type',
    utc: ' UTC',
    replaceT: 'T',
    one: 1,
    six: 6,
    seven: 7,
    eight: 8,
    thirteen: 13,
    fourteen: 14,
    fifteen: 15,
    sixteen: 16,
    twenty: 20,
    pageTitle: 'EmpiRx Health Reporting Portal ',
    termsOfUse: 'Terms of Use',
    privacyStatement: 'Privacy Statement',
    hyphen: '- ',
    financialDocuments: 'Financial Documents',
    subGroupId: 'subGroupId',
    isInvoice: 'isInvoice',
    managementAccessLevel: 'Benefits Management Access Level',
    hasReportsAccess: 'Reports Access',
    hasManagementAccess: 'Management Access',
  };
  public static GENDER_LIST = [
    { key: 'M', name: 'Male' },
    { key: 'F', name: 'Female' },
    { key: 'U', name: 'Unknown' },
  ];
  public static DISPLAY_GENDER = {
    M: 'Male',
    F: 'Female',
    U: 'Unknown',
  };
  public static PHONE_LABELS = {
    homePhone: 'Home Phone',
    cellPhone: 'Cell Phone',
    workPhone: 'Work Phone',
  };
  public static DISPLAY_COVERAGE_TYPE = {
    FAM: 'Family',
    IND: 'Individual',
  };
  public static PHONE_NUMBER_TYPES = ['Work Phone', 'Home Phone', 'Cell Phone'];
  public static EMAIL_VALIDATION_PATTERN = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$';
  public static NAME_VALIDATION_PATTERN = "^[A-Za-z0-9À-ÖØ-öø-ÿ-_'‘’.,~` \p{L}\s]{1,50}$";
  public static readonly ARRAYS = {
    parenIdColumns: ['filename', 'fileType', 'clientName', 'dateModified'],
    clientIdColumns: ['filename', 'fileType', 'dateModified'],
  };

  public static readonly STATES = [
    { name: 'ALABAMA', value: 'AL' },
    { name: 'ALASKA', value: 'AK' },
    { name: 'AMERICAN SAMOA', value: 'AS' },
    { name: 'ARIZONA', value: 'AZ' },
    { name: 'ARKANSAS', value: 'AR' },
    { name: 'CALIFORNIA', value: 'CA' },
    { name: 'COLORADO', value: 'CO' },
    { name: 'CONNECTICUT', value: 'CT' },
    { name: 'DELAWARE', value: 'DE' },
    { name: 'DISTRICT OF COLUMBIA', value: 'DC' },
    { name: 'FLORIDA', value: 'FL' },
    { name: 'GEORGIA', value: 'GA' },
    { name: 'GUAM', value: 'GU' },
    { name: 'HAWAII', value: 'HI' },
    { name: 'IDAHO', value: 'ID' },
    { name: 'ILLINOIS', value: 'IL' },
    { name: 'INDIANA', value: 'IN' },
    { name: 'IOWA', value: 'IA' },
    { name: 'KANSAS', value: 'KS' },
    { name: 'KENTUCKY', value: 'KY' },
    { name: 'LOUISIANA', value: 'LA' },
    { name: 'MAINE', value: 'ME' },
    { name: 'MARYLAND', value: 'MD' },
    { name: 'MASSACHUSETTS', value: 'MA' },
    { name: 'MICHIGAN', value: 'MI' },
    { name: 'MINNESOTA', value: 'MN' },
    { name: 'MISSISSIPPI', value: 'MS' },
    { name: 'MISSOURI', value: 'MO' },
    { name: 'MONTANA', value: 'MT' },
    { name: 'NEBRASKA', value: 'NE' },
    { name: 'NEVADA', value: 'NV' },
    { name: 'NEW HAMPSHIRE', value: 'NH' },
    { name: 'NEW JERSEY', value: 'NJ' },
    { name: 'NEW MEXICO', value: 'NM' },
    { name: 'NEW YORK', value: 'NY' },
    { name: 'NORTH CAROLINA', value: 'NC' },
    { name: 'NORTH DAKOTA', value: 'ND' },
    { name: 'NORTHERN MARIANA IS', value: 'MP' },
    { name: 'OHIO', value: 'OH' },
    { name: 'OKLAHOMA', value: 'OK' },
    { name: 'OREGON', value: 'OR' },
    { name: 'PENNSYLVANIA', value: 'PA' },
    { name: 'PUERTO RICO', value: 'PR' },
    { name: 'RHODE ISLAND', value: 'RI' },
    { name: 'SOUTH CAROLINA', value: 'SC' },
    { name: 'SOUTH DAKOTA', value: 'SD' },
    { name: 'TENNESSEE', value: 'TN' },
    { name: 'TEXAS', value: 'TX' },
    { name: 'UTAH', value: 'UT' },
    { name: 'VERMONT', value: 'VT' },
    { name: 'VIRGINIA', value: 'VA' },
    { name: 'VIRGIN ISLANDS', value: 'VI' },
    { name: 'WASHINGTON', value: 'WA' },
    { name: 'WEST VIRGINIA', value: 'WV' },
    { name: 'WISCONSIN', value: 'WI' },
    { name: 'WYOMING', value: 'WY' },
  ];

  public static DAYS = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  public static readonly RELATIONSHIP_CODE = [
    { name: 'Spouse', value: '02' },
    { name: 'Child', value: '03' },
    { name: 'Other', value: '04' },
    { name: 'Student', value: '05' },
    { name: 'Disabled', value: '06' },
    { name: 'Adult dependent', value: '07' },
    { name: 'SIG other', value: '08' },
    { name: 'Ex-spouse', value: '09' },
  ];

  public static RELATIONSHIP_Value = {
    '02': 'Spouse',
    '03': 'Child',
    '04': 'Other',
    '05': 'Student',
    '06': 'Disabled',
    '07': 'Adult dependent',
    '08': 'SIG other',
    '09': 'Ex-spouse',
  };

  public static RELATIONSHIP_VALUES = {
    '2': 'Spouse',
    '3': 'Child',
    '4': 'Other',
    '5': 'Student',
    '6': 'Disabled',
    '7': 'Adult dependent',
    '8': 'SIG other',
    '9': 'Ex-spouse',
  };

  public static readonly RELATIONSHIP_CODES_ALL = {
    CARDHOLDER: '1',
    SPOUSE: '2',
    CHILD: '3',
    OTHER: '4',
    STUDENT: '5',
    DISABLED: '6',
    ADULT_DEPENDENT: '7',
    SIG_OTHER: '8',
    EX_SPOUSE: '9',
  };

  public static PHARMACY_MAIL_ORDER_NPI_ARRAY = ['1821120981'];

  public static FLEX_TAG_KEY_MAPPING = {
    'WELLPRG': ['flexTagName2', 'flexTagStartDate2', 'flexTagEndDate2', 'flexTag2'],
    'PICAPRG': ['flexTagName3', 'flexTagStartDate3', 'flexTagEndDate3', 'flexTag3'],
    'FORCE65': ['flexTagName', 'flexTagStartDate', 'flexTagEndDate', 'flexTag']
  }

  public static COVERAGE_TYPES = {
    'WELLPRG': 'Wellness Program',
    'PICAPRG': 'PICA Coverage',
    'FORCE65': 'No RX Coverage'
  }

  public static readonly messageArray = [
    "Existing Member Found",
    "Error creating patient : Patient already exists",
    "Error creating patient : Error occurred calling tredium service 'POST epx-pharm-prod /api/eligibility-svc/patient/",
    "Error Updating Subscriber",
    "Signed integer is greater than maximum",
    "name ‘card_id_structure’ is not found"
  ]

  public static readonly AUDIT_LOGS = {
    addMember: {
      action: {
        addNewMember: { change: 'Member Add', comments: 'Added a New Member' },
      },
    },
    addDependent: {
      action: {
        addNewDependent: {
          change: 'Dependents',
          comments: 'Added a New Dependent',
        },
      },
    },
    updateMember: {
      action: {
        updateMember: {
          change: 'Member Add',
          comments: 'Updated %FIELD_NAME%',
        },
      },
    },
    requestIDCard: {
      action: {
        requestNewIDCard: {
          change: 'Other',
          comments: 'Member ID Card Request - Response: %REASON%',
        },
      },
    },
    addContactInfo: {
      action: {
        updateAddress: {
          change: 'Basic Information',
          comments: 'Changed existing address',
        },
        updateZipCode: {
          change: 'Basic Information',
          comments: 'Changed Zip Code',
        },
        addNewPhone: {
          change: 'Basic Information',
          comments: 'Added new phone number',
        },
        updatePhone: {
          change: 'Basic Information',
          comments: 'Changed exisitng phone number',
        },
        addEmail: {
          change: 'Basic Information',
          comments: 'Added new Email',
        },
        updateEmail: {
          change: 'Basic Information',
          comments: 'Changed existing Email',
        },
      },
    },
    updateCoverage: {
      action: {
        memberRxCoverageTerminated: {
          change: 'Benefit',
          comments: 'Member Rx Coverage termed on %DATE%',
        },
        memberRxCoverageAdded: {
          change: 'Benefit',
          comments: 'Member enrolled in Rx Coverage effective %DATE%',
        },
        memberNoRxCoverageTerminated: {
          change: 'Benefit',
          comments: 'Member %COVERAGE_TYPE% termed on %DATE%',
        },
        memberNoRxCoverageAdded: {
          change: 'Benefit',
          comments: 'Member enrolled in %COVERAGE_TYPE% effective %DATE%',
        },
        memberCoverageTerminated: {
          change: 'Benefit',
          comments: 'Member termed from group %GROUP_NAME% effective %DATE%',
        },
        memberCoverageAdded: {
          change: 'Benefit',
          comments: 'Member enrolled in group %GROUP_NAME% effective %DATE%',
        },
        memberGroupChanged: {
          change: 'Benefit',
          comments: 'Group changed',
        },
        changedEffectiveDate: {
          change: 'Benefit',
          comments: 'Changed effective date',
        },
        changedTermedDate: {
          change: 'Benefit',
          comments: 'Changed termed date',
        },
        changedTermedDateCardHolder: {
          change: 'Benefit',
          comments: 'Changed termed date for Person Codes -',
        },
      },
    },
  };
}

export enum ClaimsStatus {
  ALL = 'All',
  REJECTED = "Rejected",
  PAID_REVERSAL = "Paid/Reversal",
  PAID = "Paid"
}

export enum OrderStatus {
  All = 'all'
}
